<template>
  <div ref="headingRef">
    <Text
      class="whitespace-pre-wrap text-[48px] leading-[56px] md:leading-[80px]"
      :class="{
        'md:text-[80px]': lineCount == 1,
        'md:text-[72px]': lineCount > 1,
        'font-bold': isRebranding,
      }"
    >
      <slot></slot>
    </Text>
  </div>
</template>

<script lang="ts" setup>
import { useWindowSize } from '@vueuse/core'
import useFeatureFlag from '~/composables/useFeatureFlag'
const windowSize = useWindowSize()

const isRebranding = await useFeatureFlag()

const headingRef = ref<HTMLElement | null>(null)

const lineCount = ref(1)
const recalculateLineCount = () => {
  const element = headingRef.value?.firstElementChild
  if (!element) {
    lineCount.value = 1
    return
  }

  const height = element.getBoundingClientRect().height
  const lineHeight = parseFloat(getComputedStyle(element).lineHeight)
  lineCount.value = Math.ceil(height / lineHeight)
}

watch(headingRef, recalculateLineCount)
watch(windowSize.width, recalculateLineCount)
</script>
